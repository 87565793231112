.nova__footer {
    padding: 2rem;
}

.nova__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.nova__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
}
