/* Careers.css */
.nova__careers {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.nova__careers-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.nova__careers-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    letter-spacing: -0.04em;
}

.nova__careers-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    margin-right: 5rem;
}

.nova__careers-content-container {
    flex: 1;
    margin-right: 2rem;
}

.nova__careers-content h2 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    color: var(--color-text);

    margin-top: 1rem;
}

.nova__careers-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.nova__careers-content-features {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


.nova__careers-content__input input {
    flex: 2;
}

.nova__careers-content__input label.checkbox input {
    margin-right: 1rem;
}

.nova__careers-content__input label.checkbox {
    display: flex;
    align-items: flex-start;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text);
    margin-bottom: 1rem;
}

.nova__careers-content__input button {
    flex: 0.6;
    /* width: 100%; */
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #0070FF;
    border: 2px solid #0070FF;
    padding: 0 1rem;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;

    border-radius: 5px;
}

.custom-file-input-label {
    flex: 1;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-radius: 5px;
    /* margin: 0 0 1rem 0; */
    display: flex;
    align-items: center;
    cursor: pointer;
    min-height: 50px;

    width: fit-content;
  }

  .custom-file-input {
    opacity: 0;
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

@media screen and (max-width: 1050px) {
    .nova__careers,
    .nova__careers-content {
        flex-direction: column;
    }

    .nova__careers-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    /* .nova__careers-content h1 {
        font-size: 48px;
        line-height: 60px;
    } */

    .nova__careers-content p {
        font-size: 16px;
        line-height: 24px;
    }
    .nova__careers-content-features {
        margin: 1rem 0 3rem;
    }

    .custom-file-input-label,
    .nova__careers-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    /* .nova__careers-content h1 {
        font-size: 36px;
        line-height: 48px;
    } */

    .nova__careers-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .nova__careers-content-features {
        margin: 1rem 0 3rem;
    }

    .custom-file-input-label,
    .nova__careers-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}