.nova__whoWeAre {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.nova__whoWeAre-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.nova__whoWeAre-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.nova__whoWeAre-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    /* margin-right: 5rem; */
}

.nova__whoWeAre-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.nova__whoWeAre-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

@media screen and (max-width: 990px) {
    .gpt3__whoWeAre {
        flex-direction: column;
    }

    .gpt3__whoWeAre-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__whoWeAre-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}