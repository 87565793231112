.nova__header {
    display: flex;
}

.nova__header-content {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
    margin-top: 5rem;
}

.nova__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.nova__header-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 35px;
    color: var(--color-text);

    margin-top: 3rem;
}

.nova__header-content__input {
    width: 100%;
    margin: 3rem 0 1rem;

    display: flex;
}

.nova__header-content__input button {
    /* flex: 0.4; */
    /* width: 100%; */
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #0070FF;
    border: 2px solid #0070FF;
    padding: 0 1rem;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;

    border-radius: 5px;
}

.nova__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nova__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .nova__header {
        flex-direction: column;
    }

    .nova__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .nova__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .nova__header-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .nova__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }

}

@media screen and (max-width: 490px) {
    .nova__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .nova__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .nova__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

}