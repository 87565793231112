.nova__getInTouch {
    display: flex;
    flex-direction: row;
}

.nova__getInTouch-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.nova__getInTouch-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    /* margin-right: 5rem; */
}

.nova__getInTouch-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    letter-spacing: -0.04em;
}

.nova__getInTouch-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.nova__getInTouch-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
}

.nova__getInTouch-content__input input.text__input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-radius: 5px;
    margin: 0 0 1rem 0;
}

.nova__getInTouch-content__input textarea.text__input {
    flex: 2;
    width: 100%;
    min-height: 100px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0.5rem 1rem;
    outline: none;
    color: #fff;

    border-radius: 5px;
    margin: 0 0 1rem 0;
}

.nova__getInTouch-content__input label.checkbox {
    display: flex;
    align-items: flex-start;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-text);
    margin-bottom: 1rem;
}

.nova__getInTouch-content__input label.checkbox input {
    margin-right: 1rem;
}

.nova__getInTouch-content__input button.submit {
    flex: 0.6;
    /* width: 100%; */
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #0070FF;
    border: 2px solid #0070FF;
    padding: 0 1rem;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;

    border-radius: 5px;
}

@media screen and (max-width: 1050px) {
    .nova__getInTouch {
        flex-direction: column;
    }

    .nova__getInTouch-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .nova__getInTouch-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .nova__getInTouch-content p {
        font-size: 16px;
        line-height: 24px;
    }

    .nova__getInTouch-content__input input, 
    .nova__getInTouch-content__input button.submit, 
    .nova__getInTouch-content__input textarea {
        font-size: 16px;
        line-height: 24px;
    }

}

@media screen and (max-width: 490px) {
    .nova__getInTouch-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .nova__getInTouch-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .nova__getInTouch-content__input input, 
    .nova__getInTouch-content__input button.submit, 
    .nova__getInTouch-content__input textarea {
        font-size: 12px;
        line-height: 16px;
    }

}