@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  /* --gradient-text: linear-gradient(90deg, rgba(32, 252, 143, 1) 0%, rgba(148, 123, 211, 1) 100%); */

    /* THIS THE HEAT */
  --gradient-text: linear-gradient(90deg, rgba(66, 255, 180, 1) 27%, rgba(0, 112, 255, 1) 100%);

  --nonGradient-text: #FFF;

  --gradient-bar: linear-gradient(90deg, rgba(66, 255, 180, 1) 27%, rgba(0, 112, 255, 1) 100%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}